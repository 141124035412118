import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import styled from 'styled-components/macro';
import { omit } from 'ramda';

import * as colors from 'src/constants/colors';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import { H2, P } from 'src/components/Styled';
import { Chevron } from 'src/components/icons';
import {
  CONTENT_HZ_PADDING_PX,
  TABLET_SCREEN_WIDTH_PX,
  TABLET_CONTENT_HZ_PADDING_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
  DESKTOP_SCREEN_WIDTH_PX,
} from 'src/constants/layout';

const MAX_LOGO_WIDTH_PX = 160;

const Styled = {
  CustomerStoriesHomeSection: styled.section`
    background: ${colors.white};
  `,
  ContentWrapper: styled(StyledContentWrapper)`
    padding-top: 5rem;
    padding-bottom: 5rem;
    max-width: 1000px;
    color: ${colors.black};
    display: flex;
    align-items: center;
  `,
  InnerContentWrapper: styled.div`
    padding: 0 0.25rem;
    position: relative;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      padding: 0 1rem;
    }
  `,
  StoriesWrapper: styled.div`
    position: relative;
  `,
  H2: styled(H2)`
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  `,
  CompanyName: styled(P)`
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    margin: 0;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      font-size: 22px;
    }
    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      font-size: 23px;
    }
  `,
  P: styled(P)`
    line-height: 27px;
    text-align: center;
    margin: 0;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      font-size: 20px;
    }
  `,
  ChevronButton: styled.button`
    border: 0;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 0;
  `,
  Slideable: styled.div`
    opacity: ${props => (props.isActive ? 1 : 0)};
    position: ${props => (props.hasLongestContent ? 'relative' : 'absolute')};
    left: 0;
    right: 0;
    top: 0;
    transition: opacity 0.5s;
  `,
  ImageWrapper: styled.div`
    width: 100%;
    max-width: ${MAX_LOGO_WIDTH_PX}px;
    text-align: center;
    margin: 1rem auto;
  `,
  CompanyLogo: styled(Img)``,
};

// Define your customer stories here
const CUSTOMER_STORIES: { companyName: string; story: string; imageId: string }[] = [
  {
    companyName: 'Empire Cleaning Supply – Our First Customer!',
    story:
      'It was over 25 years ago that we sold our first racking project to Empire Cleaning Supply. Staples center and many other notable southland venues all look to Empire for their supplies. We have enjoyed taking care of their racking needs for the last quarter of a century. The Empire ownership are great two-ply guys!',
    imageId: 'empire',
  },
  {
    companyName: 'UnderArmour',
    story:
      "We have recently purchased over 800,000 square feet of warehouse equipment from Under Armour's facilities in Baltimore, MD and Rialto, CA. It's been a gratifying experience working closely and successfully with Under Armour's team of talented engineers and operations executives.",
    imageId: 'underarmour',
  },
  {
    companyName: "Warner Brother's Studios",
    story:
      "We're on the Warner Brother's Studios lot more than some of its stars! From specialized garment racks in their massive costume department to building multi-tiered catwalk mezzanines in sound stages; we're delighted that ATB is their go-to source for all of their material handling needs.",
    imageId: 'warner',
  },
  {
    companyName: 'Snyder Diamond',
    story:
      "California's most prestigious supplier of kitchen appliances and bathroom fixtures has also been a long term loyal customer. Recognized worldwide, a close relationship with Syder Diamond means the world to us. Thank you Russ Diamond!",
    imageId: 'snyder',
  },
  {
    companyName: 'Glenair Inc. – Mission-Critical Interconnect Solutions',
    story:
      'Like being friends with the smartest kid in the class. Glenair supplies all sorts of sophisticated connectors and fittings for aerospace, military, and telecommunications industries worldwide. We are very happy that they feel ATB is real smart when it comes to handling their huge warehouse equipment needs. A 20 plus year customer.',
    imageId: 'glenair',
  },
];

// Find the array indeces of the customer stories with longest company name and story content
let longestCompanyNameIndex = 0;
let maxCompanyNameLength = 0;
let longestStoryIndex = 0;
let maxStoryLength = 0;
for (let i = 0; i < CUSTOMER_STORIES.length; i += 1) {
  const story = CUSTOMER_STORIES[i];
  if (story.companyName.length > maxCompanyNameLength) {
    maxCompanyNameLength = story.companyName.length;
    longestCompanyNameIndex = i;
  }
  if (story.story.length > maxStoryLength) {
    maxStoryLength = story.story.length;
    longestStoryIndex = i;
  }
}
const LONGEST_COMPANY_NAME_INDEX = longestCompanyNameIndex;
const LONGEST_STORY_INDEX = longestStoryIndex;

const CustomerStoriesHomeSection: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const data = useStaticQuery(
    graphql`
      query {
        empire: file(relativePath: { eq: "empire-cleaning-supply-logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        glenair: file(relativePath: { eq: "glenair-logo.webp" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        snyder: file(relativePath: { eq: "snyder-diamond-logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        underarmour: file(relativePath: { eq: "underarmour-logo2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        warner: file(relativePath: { eq: "warner-bros-logo2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const customerStories = CUSTOMER_STORIES.map(customerStory => ({
    ...omit(['imageId'], customerStory),
    logoImage: data[customerStory.imageId].childImageSharp.fluid,
  }));

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    if (value === 'prev') {
      setActiveIndex(
        activeIndex > 0 ? activeIndex - 1 : CUSTOMER_STORIES.length - 1,
      );
    } else if (value === 'next') {
      setActiveIndex((activeIndex + 1) % CUSTOMER_STORIES.length);
    }
  };

  return (
    <Styled.CustomerStoriesHomeSection>
      <Styled.ContentWrapper>
        <Styled.ChevronButton value="prev" onClick={handleClick}>
          <Chevron orientation="left" size={40} />
        </Styled.ChevronButton>
        <Styled.InnerContentWrapper>
          {customerStories.map((story, i) => (
            <Styled.Slideable
              key={story.companyName}
              isActive={activeIndex === i}
              hasLongestContent={i === LONGEST_COMPANY_NAME_INDEX}
            >
              <Styled.CompanyName>{story.companyName}</Styled.CompanyName>
            </Styled.Slideable>
          ))}
          <Styled.H2>Some of Our Customers</Styled.H2>
          <Styled.StoriesWrapper>
            {customerStories.map((story, i) => (
              <Styled.Slideable
                key={story.companyName}
                isActive={activeIndex === i}
                hasLongestContent={i === LONGEST_STORY_INDEX}
              >
                <Styled.P>{story.story}</Styled.P>
                <Styled.ImageWrapper>
                  <Styled.CompanyLogo fluid={story.logoImage} />
                </Styled.ImageWrapper>
              </Styled.Slideable>
            ))}
          </Styled.StoriesWrapper>
        </Styled.InnerContentWrapper>
        <Styled.ChevronButton value="next" onClick={handleClick}>
          <Chevron size={40} />
        </Styled.ChevronButton>
      </Styled.ContentWrapper>
    </Styled.CustomerStoriesHomeSection>
  );
};

export default CustomerStoriesHomeSection;
