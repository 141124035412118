import React from 'react';
import styled from 'styled-components/macro';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import { H2, P } from 'src/components/Styled';
import * as colors from 'src/constants/colors';
import {
  TABLET_SCREEN_WIDTH_PX,
  LARGE_MOBILE_SCREEN_WIDTH_PX,
} from 'src/constants/layout';

const RED_SQUARE_WIDTH_PX = 28;
const BLUE_SQUARE_WIDTH_PX = 60;
const SQUARES_OFFSET_PX = 4;
const MOBILE_TO_DESKTOP_RATIO = 0.5;

const Styled = {
  ServicesHomeSection: styled.section`
    background: ${colors.grayBg3};
  `,
  ContentWrapper: styled(StyledContentWrapper)`
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: ${colors.black};
  `,
  H2: styled(H2)`
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  `,
  HeaderSubtext: styled(P)`
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: ${colors.blueishBlack};
    margin: 0.75rem 0;
  `,
  ServiceSubsections: styled.div`
    margin: 3rem 0;
  `,
  ServicesRow: styled.div`
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
      margin-top: 2rem;
    }

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      flex-direction: row;
    }
  `,
  ServiceSubsection: styled.div`
    display: flex;

    &:not(:first-child) {
      margin-top: 2rem;
    }

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      flex: 1;
      &:not(:first-child) {
        margin-top: 0;
      }
      &:first-child {
        padding-right: 2rem;
      }
    }
  `,
  ServiceTextWrapper: styled.div``,
  ServiceTitle: styled.h3`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 1rem;
  `,
  ServiceDescription: styled(P)`
    margin-bottom: 0;
  `,
  IconWrapper: styled.div`
    position: relative;
    padding-left: ${SQUARES_OFFSET_PX * MOBILE_TO_DESKTOP_RATIO}px;
    padding-top: ${SQUARES_OFFSET_PX * MOBILE_TO_DESKTOP_RATIO}px;
    margin-right: 1rem;

    @media only screen and (min-width: ${LARGE_MOBILE_SCREEN_WIDTH_PX}px) {
      padding-left: ${SQUARES_OFFSET_PX}px;
      padding-top: ${SQUARES_OFFSET_PX}px;
      margin-right: 1.5rem;
    }
  `,
  BlueSquare: styled.div`
    width: ${BLUE_SQUARE_WIDTH_PX * MOBILE_TO_DESKTOP_RATIO}px;
    height: ${BLUE_SQUARE_WIDTH_PX * MOBILE_TO_DESKTOP_RATIO}px;
    background: ${colors.darkBlue};

    @media only screen and (min-width: ${LARGE_MOBILE_SCREEN_WIDTH_PX}px) {
      width: ${BLUE_SQUARE_WIDTH_PX}px;
      height: ${BLUE_SQUARE_WIDTH_PX}px;
      margin-right: 1.5rem;
    }
  `,
  RedSquare: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${RED_SQUARE_WIDTH_PX * MOBILE_TO_DESKTOP_RATIO}px;
    height: ${RED_SQUARE_WIDTH_PX * MOBILE_TO_DESKTOP_RATIO}px;
    background: ${colors.richRed};

    @media only screen and (min-width: ${LARGE_MOBILE_SCREEN_WIDTH_PX}px) {
      width: ${RED_SQUARE_WIDTH_PX}px;
      height: ${RED_SQUARE_WIDTH_PX}px;
      margin-right: 1.5rem;
    }
  `,
};

const SERVICES = [
  // Row 1
  [
    {
      title: 'National Buyer And Seller',
      shortDescription:
        'We move any quantity of pallet racking and warehouse equipment throughout the nation.  ',
    },
    {
      title: 'Permitting',
      shortDescription:
        'ATB can relieve the greatest headache known to the business... permitting!  Allow us to take on this frustrating part of the job, while you sit back and relax.',
    },
  ],
  // Row 2
  [
    {
      title: 'Design and Layout Consultation',
      shortDescription:
        'The difference between an out and a homerun is a matter of inches.  Warehouse layouts are our homerun specialty!  We know how to maximize your warehouse space where the flow of men and machinery are in perfect harmony.',
    },
    {
      title: 'Material Installation and Relocation',
      shortDescription:
        'As a fully licensed, bonded and insured contractor,  ATB Material Handling’s professional and courteous crews specialize in both the dismantling and installation of warehouse systems;  in both a safe and expeditious manner.',
    },
  ],
];

interface ServiceProps {
  title: string;
  shortDescription: string;
}

const ServiceSubsection: React.FC<ServiceProps> = ({ title, shortDescription }) => (
  <Styled.ServiceSubsection>
    <Styled.IconWrapper>
      <Styled.BlueSquare />
      <Styled.RedSquare />
    </Styled.IconWrapper>
    <Styled.ServiceTextWrapper>
      <Styled.ServiceTitle>{title}</Styled.ServiceTitle>
      <Styled.ServiceDescription>{shortDescription}</Styled.ServiceDescription>
    </Styled.ServiceTextWrapper>
  </Styled.ServiceSubsection>
);

const ServicesHomeSection: React.FC = () => (
  <Styled.ServicesHomeSection>
    <Styled.ContentWrapper>
      <Styled.H2 color={colors.blueishBlack}>Our Services</Styled.H2>
      <Styled.HeaderSubtext>And how they can help you</Styled.HeaderSubtext>
      <Styled.ServiceSubsections>
        {SERVICES.map((serviceRow, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Styled.ServicesRow key={index}>
            {serviceRow.map(service => (
              <ServiceSubsection key={service.title} {...service} />
            ))}
          </Styled.ServicesRow>
        ))}
      </Styled.ServiceSubsections>
    </Styled.ContentWrapper>
  </Styled.ServicesHomeSection>
);

export default ServicesHomeSection;
