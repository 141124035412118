import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'gatsby';
import { H2, P, basePStyles } from 'src/components/Styled';
import * as colors from 'src/constants/colors';
import {
  MOBILE_CONTENT_HZ_PADDING_PX,
  DESKTOP_SCREEN_WIDTH_PX,
} from 'src/constants/layout';

const Styled = {
  UsedGearHomeSection: styled.section``,
  ContentWrapper: styled.div`
    padding-right: ${MOBILE_CONTENT_HZ_PADDING_PX}px;
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${colors.white};

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      padding-right: 9rem;
    }
  `,
  Subtext: styled.h2`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.white};
    margin-top: 0;
    margin-bottom: 0.75rem;
  `,
  H2: styled(H2)``,
  P: styled(P)``,
  Link: styled(Link)`
    text-decoration: none;
    ${basePStyles}
    font-weight: 500;
    color: ${colors.white};

    &:hover {
      text-decoration: underline;
    }
  `,
};

const UsedGearHomeSection: React.FC = () => (
  <Styled.UsedGearHomeSection>
    <Styled.ContentWrapper>
      <Styled.Subtext>Our Specialty</Styled.Subtext>
      <Styled.H2>We will buy your used Pallet Rack and Warehouse Equipment</Styled.H2>
      <Styled.P>
         Let's talk! Upon request, we will provide expert evaluations of pallet rack and warehouse materials.
      </Styled.P>
      {/* TODO: <Styled.Link to="/">Learn More -&gt;</Styled.Link> */}
    </Styled.ContentWrapper>
  </Styled.UsedGearHomeSection>
);

export default UsedGearHomeSection;
