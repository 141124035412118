import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components/macro';
import ForkliftImageData from 'src/images/forklift-white.svg';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import { H2, P, basePStyles } from 'src/components/Styled';
import * as colors from 'src/constants/colors';
import {
  TABLET_SCREEN_WIDTH_PX,
  DESKTOP_SCREEN_WIDTH_PX,
} from 'src/constants/layout';

const Styled = {
  ProductsHomeSection: styled.section`
    background: ${colors.white};
  `,
  ContentWrapper: styled(StyledContentWrapper)`
    padding-top: 5rem;
    padding-bottom: 5rem;
    color: ${colors.black};
  `,
  H2: styled(H2)`
    margin-top: 0;
    margin-bottom: 2rem;
  `,
  MainContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 3rem 0;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      flex-direction: row;
    }
  `,
  ImageBg: styled.div`
    background: ${colors.grayBg5};
    width: 100%;
    margin-bottom: 3rem;
    padding: 2rem 0;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      width: 80%;
      margin-right: 2rem;
      margin-bottom: 0;
    }
    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      width: 45%;
    }
  `,
  ImageWrapper: styled.img`
    width: 90%;
    height: 100%;
    position: relative;
    left: -24px;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      width: 100%;
    }
  `,
  ProductSubsections: styled.div`
    position: relative;
    align-self: center;
  `,
  ProductsRow: styled.div`
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
      margin-top: 2rem;
    }

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      flex-direction: row;
    }
  `,
  ProductSubsection: styled.div`
    &:not(:first-child) {
      margin-top: 2rem;
    }

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      flex-direction: row;
      flex: 1;
      &:first-child {
        padding-right: 3rem;
      }
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  `,
  ProductTitle: styled.h3`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 1rem;
  `,
  ProductDescription: styled(P)`
    letter-spacing: 0.001em;
    margin-bottom: 0.5rem;
  `,
  Link: styled(Link)`
    ${basePStyles}
    font-weight: 500;
    text-decoration: none;
    color: ${colors.black};

    &:hover {
      text-decoration: underline;
    }
  `,
};

const PRODUCTS = [
  // Row 1
  [
    {
      title: 'Complete Racking Systems',
      description:
        'Uprights, Load Beams, Wire Decks, and more!  We have all the necessities for racking success.',
      // learnMoreLink: '/products',
    },
    {
      title: 'Boltless Shelving',
      description:
        'Handstack storage made simple.  Best solution to your lightweight storage needs.',
      // learnMoreLink: '/products',
    },
  ],
  // Row 2
  [
    {
      title: 'Conveyors',
      description:
        'Extendable conveyors and other roller systems quickly move your products from here to there.',
      // learnMoreLink: '/products',
    },
    {
      title: 'Mezzanines',
      description: 'Elevate your storage capacity to a whole new level with a mezzanine system.',
      // learnMoreLink: '/products',
    },
  ],
];

interface ProductProps {
  title: string;
  description: string;
  learnMoreLink: string;
}

const ProductSubsection: React.FC<ProductProps> = ({
  title,
  description,
  learnMoreLink,
}) => (
  <Styled.ProductSubsection>
    <Styled.ProductTitle>{title}</Styled.ProductTitle>
    <Styled.ProductDescription>{description}</Styled.ProductDescription>
    {/* <Styled.Link to={learnMoreLink}>Learn More -&gt;</Styled.Link> */}
  </Styled.ProductSubsection>
);

const ProductsHomeSection: React.FC = () => (
  <Styled.ProductsHomeSection>
    <Styled.ContentWrapper>
      <Styled.H2>Products That Get The Job Done</Styled.H2>
      <Styled.MainContentWrapper>
        <Styled.ImageBg>
          <Styled.ImageWrapper src={ForkliftImageData} />
        </Styled.ImageBg>

        <Styled.ProductSubsections>
          {PRODUCTS.map(serviceRow => (
            <Styled.ProductsRow key={serviceRow[0].title}>
              {serviceRow.map(service => (
                <ProductSubsection key={service.title} {...service} />
              ))}
            </Styled.ProductsRow>
          ))}
        </Styled.ProductSubsections>
      </Styled.MainContentWrapper>
    </Styled.ContentWrapper>
  </Styled.ProductsHomeSection>
);

export default ProductsHomeSection;
