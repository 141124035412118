import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { H2 } from 'src/components/Styled';
import * as colors from 'src/constants/colors';
import {
  CONTENT_HZ_PADDING_PX,
  TABLET_SCREEN_WIDTH_PX,
  TABLET_CONTENT_HZ_PADDING_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
  DESKTOP_SCREEN_WIDTH_PX,
} from 'src/constants/layout';
import { encodeUrlQueryParams } from 'src/utils/encodeUrlQueryParams';

const baseInputStyles = css`
  width: 100%;
  display: block;
  padding: 0.5rem;
  border: 1px solid ${colors.inputBorderGray};
  border-radius: 4px;
  margin-top: 0.25rem;
  box-sizing: border-box;
  outline: none;
`;
const Styled = {
  EmailSubscriptionsHomeSection: styled.section`
    background: ${colors.richRed};
  `,
  ContentWrapper: styled.div`
    padding-left: ${MOBILE_CONTENT_HZ_PADDING_PX}px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: ${colors.white};
    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      padding-left: ${TABLET_CONTENT_HZ_PADDING_PX}px;
    }
    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      padding-left: ${CONTENT_HZ_PADDING_PX}px;
    }
  `,
  H2: styled(H2)`
    margin-top: 0;
    margin-bottom: 1rem;
  `,
  FormSection: styled.section``,
  EmailSubFormWrapper: styled.div``,
  EmailSubFormTitle: styled.h2`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  `,
  EmailSubForm: styled.form``,
  FormLabel: styled.label`
    width: 100%;
    display: block;
    margin-top: 1rem;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.001em;
    color: ${colors.white};
  `,
  NameInputs: styled.div`
    display: flex;
    > :first-child {
      margin-right: 0.5rem;
    }
    > :last-child {
      margin-left: 0.5rem;
    }
  `,
  Input: styled.input`
    ${baseInputStyles}
    flex: 1;
  `,
  SubmitButton: styled.button`
    width: 100%;
    box-sizing: border-box;
    background: ${colors.darkBlue};
    color: ${colors.white};
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.001em;
    padding: 0.75rem 2rem;
    margin-top: 1.25rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  `,
};

interface Props {
  title?: string;
}
const EmailSubscriptionForm: React.FC<Props> = ({ title }) => {
  const [formState, setFormState] = useState<Record<string, string>>({});
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

  const handleChange = (e: React.MouseEvent<HTMLInputElement>) => {
    setSubmitSuccess(false);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeUrlQueryParams({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then((res: any) => {
        if (res.status < 200 || res.status > 299) {
          throw new Error(res);
        }
        setSubmitSuccess(true);
      })
      .catch(error => {
        // TODO-PL: Report errors to a reliable error tracking service, eg. Sentry (https://sentry.io)
        console.error(error);
        alert(
          'Sorry! Something went wrong. Please try again later or give us a call at (323) 726-9424 if the issue persists!',
        );
      });
  };
  return (
    <Styled.EmailSubFormWrapper>
      {title && <Styled.EmailSubFormTitle>{title}</Styled.EmailSubFormTitle>}
      <Styled.EmailSubForm
        name="email-subscription"
        method="post"
        onSubmit={handleSubmit}
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="email-subscription" />
        <Styled.FormLabel>
          Email
          <Styled.Input
            name="email"
            value={formState.email || ''}
            type="email"
            onChange={handleChange}
          />
        </Styled.FormLabel>
        <Styled.NameInputs>
          <Styled.FormLabel>
            First Name
            <Styled.Input
              name="firstName"
              value={formState.firstName || ''}
              type="text"
              onChange={handleChange}
            />
          </Styled.FormLabel>
          <Styled.FormLabel>
            Last Name
            <Styled.Input
              name="lastName"
              value={formState.lastName || ''}
              type="text"
              onChange={handleChange}
            />
          </Styled.FormLabel>
        </Styled.NameInputs>
        <Styled.SubmitButton type="submit" disabled={submitSuccess}>
          {submitSuccess ? 'Thank you!' : 'Submit'}
        </Styled.SubmitButton>
      </Styled.EmailSubForm>
    </Styled.EmailSubFormWrapper>
  );
};

const EmailSubscriptionsHomeSection: React.FC = () => (
  <Styled.EmailSubscriptionsHomeSection>
    <Styled.ContentWrapper>
      <Styled.H2>Subscribe for Email Updates</Styled.H2>
      <EmailSubscriptionForm />
    </Styled.ContentWrapper>
  </Styled.EmailSubscriptionsHomeSection>
);

export default EmailSubscriptionsHomeSection;
