import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { omit } from 'ramda';

import styled from 'styled-components/macro';

import BackgroundImage from 'gatsby-background-image';
import Layout from 'src/components/Layout';
import SEO from 'src/components/Seo';
import Logo from 'src/components/Logo';
import AlternatingSection from 'src/components/AlternatingSection';
import ServicesHomeSection from 'src/components/ServicesHomeSection';
import ProductsHomeSection from 'src/components/ProductsHomeSection';
import UsedGearHomeSection from 'src/components/UsedGearHomeSection';
import EmailSubscriptionsHomeSection from 'src/components/EmailSubscriptionsHomeSection';
import CustomerStoriesHomeSection from 'src/components/CustomerStoriesHomeSection';
import CustomGatsbyLink from 'src/components/CustomGatsbyLink';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import { H1, H2, P, HeroSubtext } from 'src/components/Styled';

import * as colors from 'src/constants/colors';
import {
  TABLET_SCREEN_WIDTH_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
  HERO_HEIGHTS_BY_PAGE,
} from 'src/constants/layout';

const Styled = {
  HeroSection: styled.section`
    height: ${HERO_HEIGHTS_BY_PAGE.home.height};
    min-height: ${HERO_HEIGHTS_BY_PAGE.home['min-height']};
    max-height: ${HERO_HEIGHTS_BY_PAGE.home['max-height']};
    color: ${colors.white};
    position: relative;
  `,
  HeroContentWrapper: styled(StyledContentWrapper)``,
  BackgroundImage: styled(BackgroundImage)`
    min-height: 100%;
    background-position: bottom center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  AlternatingSections: styled.div`
    margin-top: 3.5rem;
    margin-bottom: 4.5rem;
  `,
  AltSectionSubtext: styled(P)`
    font-size: 20px;
    line-height: 27px;
  `,
  UsedGearAndEmailSubscriptionFormWrapper: styled.div`
    width: 100%;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: ${MOBILE_CONTENT_HZ_PADDING_PX}px;
      top: -1rem;
      bottom: -1rem;
      background: ${colors.darkBlue};
    }
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      right: 0;
      top: 0;
      bottom: 0;
      background: ${colors.richRed};
      z-index: -1;
    }
    > * {
      position: relative;
    }
    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      &::before {
        right: 50%;
      }
    }
  `,
  ContentWrapper: styled(StyledContentWrapper)`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      flex-direction: row;
      align-items: center;
      > * {
        width: 50%;
      }
    }
  `,
  Link: styled(props => (
    <CustomGatsbyLink
      {...omit(
        ['isActive', 'lightText'],
        props as GatsbyLinkProps<Record<string, unknown>>,
      )}
    />
  ))`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
  `,
};

const HomePage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "rack home 2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        stackedPallets: file(relativePath: { eq: "orange.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        experienceMatters: file(relativePath: { eq: "experience-matters.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        palletsFromAbove: file(relativePath: { eq: "character counts 2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );
  const heroImgData = data.hero.childImageSharp.fluid;
  const stackedPalletsImgData = data.stackedPallets.childImageSharp.fluid;
  const expMattersImgData = data.experienceMatters.childImageSharp.fluid;
  const palletsImgData = data.palletsFromAbove.childImageSharp.fluid;

  return (
    <Layout page="home">
      <SEO title="Home" />

      <Styled.HeroSection>
        <Styled.BackgroundImage Tag="section" fluid={heroImgData}>
          <Styled.HeroContentWrapper>
            <Logo color={colors.white} />
            <H1>ATB Material Handling</H1>
            <HeroSubtext>
              Absolutely The Best                     
              warehouse solutions since 1995

            </HeroSubtext>
          </Styled.HeroContentWrapper>
        </Styled.BackgroundImage>
      </Styled.HeroSection>

      <Styled.AlternatingSections>
        <AlternatingSection image={stackedPalletsImgData} index={0}>
          <H2>Unrivaled Inventory</H2>
          <Styled.AltSectionSubtext>
            All your needs at bargain pricing
          </Styled.AltSectionSubtext>
          <P>
            Over the years, we’ve attracted the most accomplished bargain hunters in
            the business world as our long-term, loyal customers.  Our massive inventory selection of pallet rack and other warehouse materials never disappoints.
          </P>
          <Styled.Link to="/products">See Our Products -&gt;</Styled.Link>
        </AlternatingSection>

        <AlternatingSection image={expMattersImgData} index={1}>
          <H2>Experience Matters</H2>
          <Styled.AltSectionSubtext>
            A quarter century and counting
          </Styled.AltSectionSubtext>
          <P>
            In business since 1995, we’ve seen it all; done it all;
            and have comfortably known what to do in any situation. At all levels: product
            knowledge, warehouse design, installation, and business life in general.
          </P>
          {/* TODO: <Styled.Link to="/services">See Our Services -&gt;</Styled.Link> */}
        </AlternatingSection>

        <AlternatingSection image={palletsImgData} index={2}>
          <H2>Character Counts</H2>
          <Styled.AltSectionSubtext>Our word is our bond</Styled.AltSectionSubtext>
          <P>
            Our stellar reputation is as important to us as the air we breathe. Professional, honest, and 
            trustworthy.  We work tirelessly to maintain a reputation worthy of the ATB name.
          </P>
        </AlternatingSection>
      </Styled.AlternatingSections>
      <ServicesHomeSection />
      <ProductsHomeSection />
      <Styled.UsedGearAndEmailSubscriptionFormWrapper>
        <Styled.ContentWrapper>
          <UsedGearHomeSection />
          <EmailSubscriptionsHomeSection />
        </Styled.ContentWrapper>
      </Styled.UsedGearAndEmailSubscriptionFormWrapper>
      <CustomerStoriesHomeSection />
    </Layout>
  );
};

export default HomePage;
