import React from 'react';
import styled from 'styled-components/macro';
import * as colors from 'src/constants/colors';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import {
  MOBILE_CONTENT_HZ_PADDING_PX,
  DESKTOP_SCREEN_WIDTH_PX,
  CONTENT_HZ_PADDING_PX,
} from 'src/constants/layout';

import Img from 'gatsby-image';

const Styled = {
  AlternatingSection: styled.section`
    position: relative;
    box-sizing: border-box;
  `,
  ContentWrapper: styled(StyledContentWrapper)`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    padding-bottom: 3rem;
    &:not(:first-child) {
      margin-top: 4rem;
    }

    overflow: hidden;
    padding-right: ${props =>
      props.isEven ? 0 : `${MOBILE_CONTENT_HZ_PADDING_PX}px`};
    padding-left: ${props =>
      props.isEven ? `${MOBILE_CONTENT_HZ_PADDING_PX}px` : 0};

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: ${CONTENT_HZ_PADDING_PX}px;
      padding-left: ${CONTENT_HZ_PADDING_PX}px;
      padding-top: 3rem;
    }
  `,
  ChildrenWrapper: styled.div`
    box-sizing: border-box;
    padding-right: ${props =>
      props.isEven ? `${2 * MOBILE_CONTENT_HZ_PADDING_PX}px` : '0'};
    padding-left: ${props => (props.isEven ? '0' : '3rem')};
    order: ${props => (props.isEven ? '1' : '2')};
    text-align: ${props => (props.isEven ? 'left' : 'right')};

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      width: 50%;
      padding-right: ${props => (props.isEven ? '5rem' : '0')};
      padding-left: ${props => (props.isEven ? '0' : '8rem')};
      text-align: left;
    }
  `,
  ImageWrapper: styled.div`
    width: 100%;
    max-width: 600px;
    align-self: ${props => (props.isEven ? 'flex-end' : 'flex-start')};
    margin-right: 0;
    margin-left: 0;
    margin-top: ${2 * MOBILE_CONTENT_HZ_PADDING_PX}px;
    order: 2;
    position: relative;

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      width: 50%;
      max-width: 600px;
      margin-right: ${props => (props.isEven ? '0' : '1rem')};
      margin-left: ${props => (props.isEven ? '1rem' : '0')};
      margin-top: 0;
      order: ${props => (props.isEven ? '2' : '1')};
      align-self: center;
    }
  `,
  Image: styled(Img)`
    width: 100%;
  `,
  MobileImageBg: styled.div`
    position: absolute;
    background-color: ${props => props.color};
    top: ${MOBILE_CONTENT_HZ_PADDING_PX}px;
    bottom: -${MOBILE_CONTENT_HZ_PADDING_PX}px;
    left: -${MOBILE_CONTENT_HZ_PADDING_PX}px;
    right: -${MOBILE_CONTENT_HZ_PADDING_PX}px;

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      display: none;
    }
  `,
  PartialBackground: styled.div`
    position: absolute;
    right: ${props =>
      props.isEven ? '0' : `calc(100% - ${1.5 * MOBILE_CONTENT_HZ_PADDING_PX}px)`};
    top: -12px;
    bottom: 3rem;
    left: ${props =>
      props.isEven ? `calc(100% - ${1.5 * MOBILE_CONTENT_HZ_PADDING_PX}px)` : '0'};
    background-color: ${props => props.color};

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      right: ${props => (props.isEven ? '0' : '55%')};
      left: ${props => (props.isEven ? '55%' : '0')};
      bottom: -12px;
      top: -12px;
    }
  `,
};

interface Props {
  children: any[];
  image: any;
  index: number;
}

const AlternatingSection: React.FC<Props> = ({ children, image, index }) => {
  const isEven = index % 2 === 0;
  const color = isEven ? colors.grayBg1 : colors.darkBlue;
  return (
    <Styled.AlternatingSection>
      <Styled.PartialBackground color={color} isEven={isEven} />
      <Styled.ContentWrapper isEven={isEven}>
        <Styled.ChildrenWrapper isEven={isEven}>{children}</Styled.ChildrenWrapper>
        <Styled.ImageWrapper isEven={isEven}>
          <Styled.MobileImageBg isEven={isEven} color={color} />
          <Styled.Image fluid={image} />
        </Styled.ImageWrapper>
      </Styled.ContentWrapper>
    </Styled.AlternatingSection>
  );
};

export default AlternatingSection;
